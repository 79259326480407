.about .content h3 {
    font-weight: 700;
    font-size: 28px;
    font-family: "Poppins", sans-serif;
}

.about .content ul {
    list-style: none;
    padding: 0;
}

.about .content ul li {
    padding: 0 0 8px 26px;
    position: relative;
}

.about .content ul i {
    position: absolute;
    font-size: 20px;
    left: 0;
    top: -3px;
    color: #ffc451;
}

.about .content p:last-child {
    margin-bottom: 0;
}
