#feature-image {
    background-image: url('services.jpg');
}

.services {
    padding-top: 20px;
}

.services .icon-box {
    padding-left: 15px;
}

.services .icon-box h4 {
    font-size: 20px;
    font-weight: 700;
    margin: 5px 0 10px 60px;
}

.services .icon-box i {
    font-size: 48px;
    float: left;
    color: #ffc451;
}

.services .icon-box p {
    font-size: 15px;
    color: #848484;
    margin-left: 60px;
}

.services .image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 400px;
}
