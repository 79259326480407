body {
    font-family: "Open Sans", sans-serif;
    color: #444444;
}

a {
    color: #ffc451;
}

a:hover {
    color: #ffd584;
    text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Raleway", sans-serif;
}
